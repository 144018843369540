/*

    TODO: 
        (1) Make ideas a prop that is defined in the PagePyrEvalEssay component.


*/

import { Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@mui/material";
import React, { useEffect } from "react";
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


const columns = [
    { id: 'cuInfo', label: 'Content Unit', minWidth: 50 },
    { id: 'studentValue', label: 'Included', minWidth: 10 },
]

const ideas_essay1 = [
    "Height and Potential Energy",
    "Relation between Potential Energy and Kinetic Energy",
    "Total energy",
    "Energy transformation and Law of Conservation of Energy",
    "Relation between initial drop and hill height",
    "Mass and energy",
]

const ideas_essay2 = [
    "Height and Potential Energy",
    "Relation between Potential Energy and Kinetic Energy",
    "Total energy",
    "Energy transformation and Law of Conservation of Energy",
    "Relation between initial drop and hill height",
    "Mass and energy",
    "Drop height and velocity",
    "Mass and velocity",
]

//Hard coded from the ESSAY_PYRAMID_COLLECTION
//It could be retrieved, but the data structure they used to store it in doesn't deserve to be used ever.
const confidence_essay1 = [
    'Medium', //1 - Height and Potential Energy
    'High', //2 - Relation between Potential Energy and Kinetic Energy
    'Low', //3 - Total energy
    'High', //4 -Energy transformation and Law of Conservation of Energy
    'Medium', //5 - Relation between initial drop and hill height
    'High' //6 - Mass and energy
]

const confidence_essay2 = [
    'High', //1 - Height and Potential Energy
    'High', //2 - Relation between Potential Energy and Kinetic Energy
    'Low', //3 - Total energy
    'Medium', //4 - Energy transformation and Law of Conservation of Energy
    'High', //5 - Relation between initial drop and hill height
    'Medium', //6 - Mass and energy
    'Low', //7 - Drop height and velocity
    'Low', //8 - Mass and velocity
]

//function for creating the rows
function createRow(cuInfo, studentValue) {
    return { cuInfo, studentValue }
}

export function PagePyrEvalFeedback(props) {

    const [rows, setRows] = React.useState([]);
    const [showFeedback, setShow] = React.useState(false);
    const [buttonText, setButtonText] = React.useState("Hide Feedback");

    useEffect(() => {
        if (props.feedback === undefined || props.feedback === "Processing..." || props.feedback === '') {
            //do nothing
            setShow(false);
        } else {
            let newRows = [];
            let ideas;
            //TODO: Use ideas_essay1 for essay 1 and use ideas_essay2 for essay 2
            if (props.essayNumber === 1) {
                ideas = ideas_essay1;
            } else {
                ideas = ideas_essay2;
            }
            props.feedback.map(function(value, i) {
                newRows.push(createRow(ideas[i], value))
            })
            setRows(newRows);
            setShow(true);
        }
    }, [props.feedback])

    const feedbackButtonClick = () => {
        if (showFeedback) {
            setShow(false);
            setButtonText("Show Feedback");
        } else {
            setShow(true);
            setButtonText("Hide Feedback");
        }
    }
    
    function yesNoSymbol(value) {
        if (value === 1) {
            return <CheckIcon sx={{ color: "green" }} />;
        } else {
            return <QuestionMarkIcon sx={{ color:"orange" }} />;
        }
    }

    //generate the table
    const generateTable = () => {
        if (showFeedback) {
            let confidence;
            if (props.essayNumber === 1) {
                confidence = confidence_essay1;
            } else {
                confidence = confidence_essay2;
            }
            return (
                <Paper sx={{ width: '80%' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="feedback-table">
                            <TableHead>
                                <TableRow key='headers'>
                                    <TableCell key='header-feedback' align="center" colSpan={2}>
                                        Feedback
                                    </TableCell>
                                    <TableCell key='header-confidence' align="center">
                                        My Confidence
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(function(row, index) {
                                    return (
                                        <TableRow key={'row-'+index}>
                                            <TableCell key={'cell-'+row.cuInfo}>
                                                {row.cuInfo}
                                            </TableCell>
                                            <TableCell key={'cell-'+row.cuInfo+'-'+row.studentValue} align="center">
                                                {yesNoSymbol(row.studentValue)}
                                            </TableCell>
                                            <TableCell key={'cell-confidence-'+index} align="center">
                                                {confidence[index]}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )
        } else {
            return null;
        }
    }

    //handles error when navigation to simulation from essay page
    if (props.feedback === undefined) {
        return null;
    } else if (props.feedback === "Processing..." || props.feedback === '') {
        return (
            <Typography>Processing...</Typography>
        )
    } else {
        if (showFeedback) {
            return (
                <div>
                    {generateTable()}
                    <br/>
                    <Button key='feedback-button' variant="contained" color="primary" onClick={() => feedbackButtonClick()}>{buttonText}</Button>
                </div>
            )
        } else {
            return (
                <div>
                    <Button key='feedback-button' variant="contained" color="primary" onClick={() => feedbackButtonClick()}>{buttonText}</Button>
                </div>
            )
        }
    }
}

export default PagePyrEvalFeedback;