import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { createTheme } from "@material-ui/core/styles";
import { createTheme as createMuiTheme } from "@mui/material/styles";
//Redux components
import { connect } from "react-redux";
import store from "../../../store";
import { setTheme } from "../../../actions/authActions";
import PropTypes from "prop-types";

function StudentDashboard(props) {

    let linkText = "/notebook";

    /* for test later 
        <br />
        <Button variant="contained" color="primary" component={Link} to={"/test"}>
            Test
        </Button>
    */

    return (
        <div>
            <Button 
                variant="contained" 
                color="primary" 
                component={Link} 
                to={linkText}
                onClick={() => {
                    // Revert to student theme for notebook if user is a teacher
                    props.auth.user.access === 2 && store.dispatch(setTheme({material: createTheme({}), mui: createMuiTheme({})}));
                  }}
            >
                Notebook
            </Button>
        </div>
    )
}

StudentDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps) (StudentDashboard);