/*

    Input Props:
        answer: The current, editable version of the essay.
        essay: An object {} that stores essays when they've been submitted to PyrEval.
        feedback: An object {} of equal length to the essay prop.

*/

import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import PagePyrEvalFeedback from './PagePyrEvalFeedback';
//Dialog stuff
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//For unlocking the next lab
import { setCurrentNavigation } from "../../../actions/lessonActions";
import { withRouter, useHistory } from "react-router-dom";

//For waiting for the save to trigger
import { Backdrop, CircularProgress } from '@mui/material';

//Store for getting user access, could be a database call eventually
import store from '../../../store';

function TabPanel(props) {
    const { children, value, index, content, onChangeHandler, submitStatus, submit, feedback, essayNumber, buttonText, ...other } = props;

    let disableStatus = (index > 0)

    const submitButton = () => {
        if (index === 0) {
            return(
                <Button key='submit-button' disabled={!submitStatus} sx={{marginLeft: 89}} variant="contained" color="primary" onClick={() => submit()}>{buttonText}</Button>
            )
        } else {
            return null;
        }
    }

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
                <PagePyrEvalFeedback feedback={feedback} essayNumber={essayNumber} />
                <br/>
                <TextField
                    disabled={disableStatus}
                    id="standard-textarea"
                    label="Your response"
                    placeholder=""
                    multiline
                    rows={20}
                    style={{ width: 800 }}
                    value={content}
                    onChange={onChangeHandler}
                />
                <br/>
                <br/>
                {submitButton()}
            </Box>
        )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function PagePyrEvalEssay(props) {
    //probably replace this with a database call in useEffect since we're moving away from the store setup
    let user = store.getState().auth.user;
    /* Ensure this URL stuff works with Caelan's changes */
    //get url params
    let url = window.location.href.split("/"); 
    const history = useHistory();

    const [value, setValue] = React.useState(0);
    const [essayContent, setEssay] = React.useState(props.answers?.answers.answer);
    //essay to object in essay obj
    const [essayToSubmit, setEssayToSubmit] = React.useState(0);
    //submitButton enabler
    const [submitEssay, setSubmitEssay] = React.useState(true);
    //feedback
    const [pyrevalFeedback, setFeedback] = React.useState(props.answers?.answers.feedback);
    //essays
    const [oldEssays, setOldEssays] = React.useState(props.answers?.answers.essays);
    //Values for submitting to PyrEval and getting feedback
    const [page, setPage] = React.useState("");
    const [classroom, setClassroom] = React.useState(props.navigation.Classroom);
    const [studentId, setStudentId] = React.useState(props.navigation.Student_Id);
    const [teacherId, setTeacherId] = React.useState("");
    const [checkFeedback, setCheckFeedback] = React.useState(true);
    //pyreval confirmation/length check/dialog
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [submitCheck, setSubmitCheck] = React.useState(false);
    const [dialogText, setDialogText] = React.useState("");
    const [dialogButtons, setDialogButtons] = React.useState([]);
    //for loading screen
    const [backdropStatus, setBackdrop] = React.useState(false);
    const [confirmationStatus, setConfirmation] = React.useState(false);

    useEffect(() => {
        let inProgressCheck = false;
        let essaySubmission = 999;
        Object.keys(pyrevalFeedback).map(function(fb, index) {
            if (pyrevalFeedback[fb] === 'Processing...') {
                inProgressCheck = true;
                setEssayToSubmit(parseInt(fb));
            }
            if (pyrevalFeedback[fb] === '' && essaySubmission > (index + 1)) {
                essaySubmission = index + 1;
            }
        });
        if (essaySubmission === 999 || inProgressCheck) {
            setSubmitEssay(false);
        } else {
            setEssayToSubmit(essaySubmission);
            setSubmitEssay(true);
        }
    }, [essayToSubmit])

    //get teacher id
    useEffect(() => {
        if (user.access != -1) {
            axios
            .post("/api/pyreval/getTeacher", {
                classId: props.navigation.Classroom,
            }).then(result => {
                //For now, since each classroom has one teacher, will be a single string.
                setTeacherId(result.data);
            }).catch(error=> {
                console.log(error);
            });
        } else {
            setTeacherId("Guest");
        }
    }, [])

    //check feedback to see if vector arrived
    useEffect(() => {
        let pageId = undefined;
        if (url.length === 5) {
            pageId = props.navigation.Labs[url[url.length - 1]].pages.overview;
            setPage(pageId);
        }
        if (pageId != null) {
        axios
            .post("/api/pyreval/getFeedback", {
                studentId: studentId,
                classId: classroom,
                pageId: pageId
            }).then(result => {
                let feedbackDiff = false;
                Object.keys(result.data).map(feedbackKey => {
                    if (result.data[feedbackKey] !== pyrevalFeedback[feedbackKey] && !Array.isArray(pyrevalFeedback[feedbackKey])) {
                        //feedback in the database is different than the local feedback
                        //there is a chance the local feedback is ahead of the database
                        if (pyrevalFeedback[feedbackKey] != '' && result.data[feedbackKey] === '') {
                            //this means the local feedback is not blank, but the database hasn't been updated yet.
                            //nothing should be done here.
                        } else {
                            feedbackDiff = true;
                        }
                    }
                });
                if (feedbackDiff) {
                    //pyreval feedback arrived, update the feedback
                    setFeedback(result.data);
                    setEssayToSubmit(-1);
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }, [checkFeedback])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCheckFeedback(!checkFeedback);
    };

    const onChangeHandler = (event) => {
        setEssay(event.target.value);
        let ansObj = {answer: event.target.value, feedback: pyrevalFeedback, essays: oldEssays}
        props.returnData({ answers: ansObj }, props.fieldKey);
    }

    //Dialog Open
    async function dialogOpen() {
        await pyrevalCheck();
        setSubmitCheck(true);
    }

    //Dialog Close
    const dialogClose = () => {
        setSubmitCheck(false);
    }

    function pyrevalCheck() {
        //splits on multiple punctuations now
        let splitSent = essayContent.split(/[.!?]/);
        let tooLongCheck = false;
        for (let sentFrag of splitSent) {
            let splitFrag = sentFrag.split(" ");
            splitFrag = splitFrag.filter(function(entry) { return entry.trim() != '' });
            //value in the comparison below is the threshold to prevent submissions
            if (splitFrag.length > 45) {
                setDialogText(["At least one of your sentences is too long to provide good feedback (shown below). It's a good practice to keep your sentences to 25 words or less. Remember to use periods and commas! You can also try breaking longer sentences into multiple sentences.",<br/>,<br/>, "\"" + sentFrag +".\""]);
                tooLongCheck = true;
                break;
            }
        }
        if (essayContent.trim().length === 0) {
            setDialogText("You haven't typed anything yet!");
            tooLongCheck = true;
        }
        if (!tooLongCheck) {
            setDialogText("If you're sure you're ready to submit, click Submit!");
            let buttons = [<Button key='close' style={{ marginRight: 10, color: 'red' }} autoFocus onClick={dialogClose}>Close</Button>, <Button key='submit-button-final' variant="contained" onClick={submit} autoFocus>Submit</Button>];
            setDialogButtons(buttons);
        } else {
            let buttons = [<Button key ='close' style={{ color: 'red' }} autoFocus onClick={dialogClose}>Close</Button>];
            setDialogButtons(buttons);
        }
    }

    const generateTabs = () => {
        let tabs = [];
        tabs.push(<Tab key="tab-key-0" label="Current Essay" id='simple-tab-0' aria-controls='simple-tabpanel-0' />);
        Object.keys(oldEssays).map(function(essay, index) {
            if (oldEssays[essay] !== '') {
                let tabId = 'simple-tab-' + (index + 1);
                let ariaController = 'simple-tabpanel-' + (index + 1);
                let labelText = index === 0 ? 'Initial Essay ' + props.essay_number : 'Revised Essay ' + props.essay_number;
                let keyId = 'tab-key-' + (index + 1);
                tabs.push(<Tab key={keyId} label={labelText} id={tabId} aria-controls={ariaController} />);
            }
        });
        return tabs;
    }
    
    const backdropTrigger = () => {
        setTimeout(function() {
            setBackdrop(false)
            setConfirmation(true);
        }, 5000);
    }

    const submit = () => {
        //This assumes only an original and one revision
        let essayVal;
        if (essayToSubmit === 1) {
            essayVal = 'O';
        } else {
            essayVal = 'R';
        }
        let newFeedback = pyrevalFeedback;
        newFeedback[essayToSubmit] = 'Processing...';
        setFeedback(newFeedback);
        let newEssay = oldEssays;
        newEssay[essayToSubmit] = essayContent;
        setOldEssays(newEssay);
        setEssayToSubmit(0);
        //local url
        let url;
        if (user.access === -1) {
            url = process.env.REACT_APP_PYREVAL_URL && process.env.REACT_APP_PYREVAL_URL.concat("/guest_submit_essay");
        } else {
            url = process.env.REACT_APP_PYREVAL_URL && process.env.REACT_APP_PYREVAL_URL.concat("/submit_essay");
        }
        if (url) {
            axios
                .post(url, {
                    student_id: studentId,
                    class_id: classroom,
                    page_id: page,
                    teacher_id: teacherId,
                    essay_number: props.essay_number,
                    essay_version: essayVal,
                    essay: essayContent,
                })
                .then(result => {
                    //console.log(result);
                })
                .catch(error => {
                    console.log(error);
                });
        }
        let ansObj = {answer: essayContent, feedback: newFeedback, essays: newEssay}
        props.returnData({ answers: ansObj }, props.fieldKey);
        dialogClose();
        setBackdrop(true);
        backdropTrigger();
        props.setRender();
        //Check if there is a lab to unlock after this essay
        let activeLab = parseInt(props.activeLab);
        if  (activeLab + 1 < props.navigation.Labs.length) {
            let newNav = props.navigation;
            newNav.Labs[activeLab + 1].unlocked = true;
            setCurrentNavigation(newNav);
            props
                .studentRequest("/api/navigation/unlocklab", {
                    nav: newNav,
                })
                .then((result) => {
                    //result
                }).catch(error => {
                    //error
                });
            //let next = props.activeLab + 1;
            //history.push(`${props.previewBaseUrl}/notebook/${next}/unlocked`);
        }
    }

    const generateTabPanels = () => {
        let tabPanels = [];
        tabPanels.push(<TabPanel key={'tab-panel-key-0'} value={value} index={0} content={essayContent} onChangeHandler={onChangeHandler} submitStatus={submitEssay} submit={() => dialogOpen()} buttonText="Submit" />);
        Object.keys(oldEssays).map(function(essay, index) {
            if (oldEssays[essay] !== '') {
                let indexValue = index + 1;
                let keyValue = 'tab-panel-key-' + (index + 1);
                tabPanels.push(<TabPanel key={keyValue} value={value} index={indexValue} content={oldEssays[essay]} feedback={pyrevalFeedback[essay]} essayNumber={props.essay_number} />);
            }
        })
        return tabPanels;
    }

    return (
        <Box sx={{ width: '75%' }}>
            <Backdrop
             sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
             open={backdropStatus}
            >
                <CircularProgress color="inherit" />
                <Typography style={{ marginLeft: -60, marginTop: 85, fontWeight: 'bold' }}>Submitting...</Typography>
            </Backdrop>
            <Dialog
            fullScreen={fullScreen}
            open={submitCheck}
            onClose={dialogClose}
            aria-labelledby="pyreval-dialog-title"
            >
                <DialogTitle id="pyreval-dialog-title">
                    {"Ready to submit your essay?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                        {dialogButtons}
                </DialogActions>
            </Dialog>
            <Dialog
             open={confirmationStatus}
             aria-labelledby="confirmation-dialog-title"
             >
                <DialogTitle id="confirmation-dialog-title">
                    {"Successfully submitted!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your essay has been successfully submitted for review.
                    </DialogContentText>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setConfirmation(false)}>Close</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Box sx={{ width: '100%' }}>
                <Typography>{props.question}</Typography>
                <br/>
            </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {generateTabs()}
            </Tabs>
        </Box>
            {generateTabPanels()}
        </Box>
    );
}

export default withRouter(PagePyrEvalEssay);