/*
    
    Input Lengths:
        "short" - 15ch
        "medium" - 30ch
        *else* "long" - 45ch

*/

import React from "react";

//Material Components
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export function PageInput(props) {
  let inputLength = "";
  if (props.inputLength == "short") {
    inputLength = "15ch";
  } else if (props.inputLength == "medium") {
    inputLength = "30ch";
  } else {
    inputLength = "45ch";
  }

  //Answer
  const [answer, setAnswer] = React.useState(props.answers?.answer || "");

  const onChangeHandler = (event) => {
    setAnswer(event.target.value);
    props.returnData({ answer: event.target.value }, props.fieldKey);
  };

  return (
    <Grid container>
      <Grid item style={{ display: "flex", gap: "0.25rem" }}>
        <Typography sx={{ paddingTop: 2 }}>{props.question}</Typography>
        <TextField
          id={props.fieldKey}
          sx={{ m: 1, width: inputLength }}
          value={answer}
          onChange={onChangeHandler}
          size="small"
        />
      </Grid>
    </Grid>
  );
}

export default PageInput;
