/*
    
    Input Lengths:
        "short" - 15ch
        "long" - 25ch

    Adornments:
        String that will be at the end of the input box.

*/

import React, { useState } from "react";

//Material Components
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none",
    },
  },
}));

export function PageNumericInput(props) {
  const classes = useStyles();

  const inputLength = props.inputLength == "long" ? "25ch" : "15ch";

  //Answer
  const [answer, setAnswer] = useState(props.answers.answer);
  //Creation
  // const [keyValue, setKey] = React.useState(props.fieldKey);

  const onChangeHandler = (event) => {
    setAnswer(event.target.value);
    props.returnData({ answer: event.target.value }, props.fieldKey);
  };

  return (
    <Grid container>
      <Grid item style={{ display: "flex", gap: "0.25rem" }}>
        <Typography sx={{ paddingTop: 2 }}>{props.question}</Typography>
        <TextField
          id={props.fieldKey}
          sx={{ m: 1, width: inputLength }}
          value={answer}
          onChange={onChangeHandler}
          type="number"
          InputProps={{
            classes: { input: classes.inputStyle },
            endAdornment: (
              <InputAdornment sx={{ paddingLeft: 1 }}>
                {props.adornment}
              </InputAdornment>
            ),
          }}
          size="small"
        />
      </Grid>
    </Grid>
  );
}

export default PageNumericInput;
