import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    buttons: {
        marginLeft: 7,
        marginRight: 7,
        marginBottom: 20
    },
}))

export default function ResearchDashboard() {

    const classes = useStyles();

    return (
        <div>
            <br/>
            <Button className={classes.buttons} variant="contained" color="primary" href="/assignlesson">
                Assign Lesson
            </Button>
            <Button className={classes.buttons} variant="contained" color="primary" href="/guestcreate">
                Guest Management
            </Button>
            <Button className={classes.buttons} variant="contained" color="primary" href="/pyreval_status">
                PyrEval Status
            </Button>
            <br/>
            <Button className={classes.buttons} variant="contained" color="primary" href="/analyticdashview">
                Teacher Dashboards
            </Button>
            <Button className={classes.buttons} variant="contained" color="primary" href="/createunit">
                Content Creator
            </Button>
        </div>
    )
}