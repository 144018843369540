import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";

import store from "../../../../store";

var fillerData = [
    {
        "_id": "62740de159d7cbb5e0ba66e8",
        "groupNumber": "Sunflower"
    },
    {
        "_id": "62740de159d7cbb5e0ba670c",
        "groupNumber": "Lettuce"
    },
    {
        "_id": "62740de159d7cbb5e0ba66e2",
        "groupNumber": "Soybean"
    },
    {
        "_id": "62740de159d7cbb5e0ba66ee",
        "groupNumber": "Squash"
    },
    {
        "_id": "62740de159d7cbb5e0ba6706",
        "groupNumber": "Green Bean"
    },
    {
        "_id": "62740de159d7cbb5e0ba66f4",
        "groupNumber": "Corn"
    },
    {
        "_id": "62740de159d7cbb5e0ba6700",
        "groupNumber": "Potato"
    },
    {
        "_id": "62740de159d7cbb5e0ba6712",
        "groupNumber": "Raddish"
    },
    {
        "_id": "62740de159d7cbb5e0ba66fa",
        "groupNumber": "Kale"
    }
  ];

export function GroupToClassKnowledgeWall(props) {

    //let navigation = store.getState().navigation.navigation;

    const [groupButtons, setButtons] = React.useState([]);

    useEffect(() => {
        var buttons = [];
        //props.prompt -- this has the prompt value in it
        //navigation.Classroom -- this has the classroom id (1 or 2)
        //api call here, replace fillerData with actual data
        fillerData.forEach(buttonInfo => {
            var url = "/discussion/" + buttonInfo._id;
            buttons.push(
                <Grid key={buttonInfo.groupNumber + "grid"} item xs={4}>
                    <Button key={buttonInfo.groupNumber + "button"} target="_blank" variant="contained" href={url}>
                        {buttonInfo.groupNumber}
                    </Button>
                </Grid>
            )
        })
        setButtons(buttons);
    }, []);

    return (
        <div>
            <br/>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}}>
                {groupButtons}
            </Grid>
        </div>
    )
}

export default GroupToClassKnowledgeWall;