import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export function AnalysisSummary(props) {
  const { baseUrl, activeLab, navigation, answers } = props;

  // Strip questions
  const questions = {};
  props.questions.forEach((question) => {
    question.description.forEach((item) => {
      if (item.props.question != undefined) {
        questions[item.props.fieldKey] = item.props.question;
      }
    });
  });

  const history = useHistory();

  let completeCount = 0;

  // Filters out keys with null answers (text components) and maps the rest
  const displayQuestions = Object.keys(answers)
    .filter((key) => answers[key])
    .map((key) => {
      if (answers[key].answer === "") {
        //blank answer
        return (
          <>
            <Typography sx={{ color: "#7d7e80" }}>{questions[key]}</Typography>
            <br />
          </>
        );
      } else {
        //answered question
        completeCount++;
        return (
          <>
            <Typography sx={{ color: "blue" }}>{questions[key]}</Typography>
            <br />
          </>
        );
      }
    });

  // Calls function defined in Notebook to update the local nav and db, then executes callback to redirect
  function unlockNextLab() {
    const nextLab = parseInt(activeLab) + 1;
    props.unlockLab(nextLab, () => history.push(`${baseUrl}/${nextLab}`));
  }

  return (
    <div>
      <Grid container justify="center" alignItems="center" direction="column">
        <Grid item>
          <Typography variant="h4">
            {completeCount}/{displayQuestions.length} Questions Complete
          </Typography>
          <br />
        </Grid>
      </Grid>
      {displayQuestions}
      <Grid container justify="center" alignItems="center" direction="column">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={completeCount < displayQuestions.length}
            onClick={
              activeLab < navigation.Labs.length - 1
                ? () => unlockNextLab()
                : null
            }
          >
            {activeLab < navigation.Labs.length - 1
              ? "Submit to Teacher"
              : "Finish!"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(AnalysisSummary);
