import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
//Icons
import Add from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
//Note Editor
import EditNote from "./EditNote";
//Backdrop
import Backdrop from "@mui/material/Backdrop";
import Paper from "@mui/material/Paper";
//Folders
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import Draggable from "react-draggable";
//Note component
import Note from "./Note";
//Delete functionality
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
//Note body tooltip
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";

/* Data structure of the notes looks like the below snippet */

/*

*Steps* is all the places the note has been edited/saved to track where students are changing the note

const notes = [
  '0': {title: "test", date: "1/1/2022", body: "this is a test", background: "#EF9971", tag: "", steps: []}
  //{title: "test", body: "this is a test", background: "#EF9971"}
]
//fresh
var newData = { "notes" : {'Initial drop': {open: false, notes: { '0': {title: "test", date: "1/1/2022", body: "this is a test", background: "#EF9971", tag: "", steps: []} } },
 'Add a hill': { },
  'Mass & energy': { },
   'Essay 1': { },
    'Drop height & speed': { },
     'Mass & speed': { },
      'Essay 2': {open: false, notes: {} }}, "notes_taken" : 1 }

*/

export default function NoteManager(props) {
  //the below boolean will enable or disable headers for notes
  const noteHeaders = false;

  const { activeLab, activeTab, navigation } = props;
  const { Student_Id, Lesson_Id } = navigation;

  const [noteData, setData] = React.useState({});
  const [open, setOpen] = React.useState(props.show);
  const [edit, setEdit] = React.useState(false);
  const [noteValues, setEditValues] = React.useState({});
  const [labNames, setLabs] = React.useState([]);

  const [visNotes, setNotes] = React.useState([]);

  //folders
  const [folderStatuses, setFolders] = React.useState([]);

  //this needs to be more complex to ensure keys aren't overlapping
  const [count, setCount] = React.useState(0);

  //delete
  const [showDelete, setDelete] = React.useState(false);
  const [selectedNoteToDelete, setSelectedDelete] = React.useState([
    { title: "", body: "" },
    "Empty",
    "Empty",
  ]);

  //date
  const [date, setDate] = React.useState("");

  //generate the date and store it
  useEffect(() => {
    var d = new Date();
    var formattedDate =
      d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
    setDate(formattedDate);
    //original note api
    //get note information
    axios
      .post("/api/answers/getnotes", {
        id: Student_Id,
        navId: Lesson_Id,
      })
      .then((response) => {
        let openStatuses = [];
        let labKeys = Object.keys(response.data.notes);
        setLabs(labKeys);
        labKeys.forEach((labKey) => {
          openStatuses.push(response.data.notes[labKey].open);
        });
        setFolders(openStatuses);
        setData(response.data.notes);
        setCount(response.data.notes_taken === undefined ? 0 : response.data.notes_taken);
      });
    //get note information using API wrapper defined in Notebook
    //commented out code is for student preview logic which does not work for andrew for some reason
    /*
    props
      .studentRequest("/api/answers/getnotes", {
        id: studentId,
        navId: navId,
      })
      .then((response) => {
        let openStatuses = [];
        let labKeys = Object.keys(response.data.notes);
        setLabs(labKeys);
        labKeys.forEach((labKey) => {
          openStatuses.push(response.data.notes[labKey].open);
        });
        setFolders(openStatuses);
        setData(response.data.notes);
        setCount(response.data.notes_taken);
      });
      */
  }, []);

  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  //to clear the visible notes
  useEffect(() => {
    if (props.clearCheck) {
      //clear visible notes
      setNotes([]);
      //reset clear to false
      props.clear(false);
    }
  }, [props.clearCheck]);

  const toggleViz = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (anchor === "open") {
      setOpen(open);
      if (!open) {
        props.close();
      }
    } else {
      setEdit(open);
    }
  };

  const createNote = (note, id) => {
    //check if note already in noteData
    var noteCheck = visNotes.filter((x) => x.keyVal === id);
    if (noteCheck.length === 0) {
      var tempNote = {
        title: note.title,
        body: note.body,
        color: note.background,
        keyVal: id,
        _id: note._id,
      };
      setNotes((prev) => [...prev, tempNote]);
    }
  };

  const closeNote = (keyVal) => {
    var tempNoteData = visNotes.filter((item) => item.keyVal != keyVal);
    setNotes(tempNoteData);
  };

  const saveNote = (title, body, bgcolor, keyVal, noteId) => {
    var newKey;
    var tempNoteData = visNotes.filter((item) => item.keyVal != keyVal);
    var editCheck = false;
    setNotes(tempNoteData);
    if (tempNoteData.length < visNotes.length) {
      newKey = keyVal;
      editCheck = true;
    } else {
      newKey = count;
      setCount((prev) => prev + 1);
    }
    setEdit(false);
    setEditValues({});
    var tempNote = { title: title, body: body, color: bgcolor, keyVal: newKey };
    var tempFullNote;
    if (editCheck) {
      let labToUpdate;
      labNames.forEach((labName) => {
        if (noteData[labName].notes.hasOwnProperty(keyVal)) {
          labToUpdate = labName;
        }
      });
      let note = noteData[labToUpdate].notes[keyVal];
      note.title = title;
      note.body = body;
      note.background = bgcolor;
      note._id = noteId;
      let newSteps = note.steps;
      let page;
      if (!activeLab || activeTab === "home") {
        page = navigation.Side_Navigation.home;
      } else {
        if (activeTab === "simulation") {
          page = navigation.Side_Navigation.simulation;
        } else if (activeTab) {
          page = navigation.Labs[activeLab].pages[activeTab];
        } else {
          page = navigation.Labs[activeLab].pages.overview;
        }
      }
      newSteps.push(page);
      note.steps = newSteps;
      let tempData = noteData;
      tempData[labToUpdate].notes[keyVal] = note;
      setData(tempData);
      props
        .studentRequest("/api/answers/editnote", {
          id: Student_Id,
          labKey: labToUpdate,
          noteId: keyVal,
          newNote: note,
          navId: Lesson_Id,
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      var currentLocation;
      if (!activeLab || activeTab === "home") {
        currentLocation = navigation.Side_Navigation.home;
      } else {
        if (activeTab === "simulation") {
          currentLocation = navigation.Side_Navigation.simulation;
        } else if (activeTab) {
          currentLocation = navigation.Labs[activeLab].pages[activeTab];
        } else {
          currentLocation = navigation.Labs[activeLab].pages.overview;
        }
      }
      tempFullNote = {
        title: title,
        body: body,
        background: bgcolor,
        date: date,
        tag: "",
        steps: [currentLocation],
        _id: "",
      };
      let tempData = noteData;
      if (!tempData[labNames[activeLab]]) {
        tempData[labNames[activeLab]] = { notes: {} };
      }
      tempData[labNames[activeLab]].notes[keyVal] = tempFullNote;
      setData(tempData);
      props
        .studentRequest("/api/answers/savenewnote", {
          id: Student_Id,
          labKey: labNames[activeLab],
          newNote: tempFullNote,
          noteKey: keyVal,
          navId: Lesson_Id,
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setNotes((prev) => [...prev, tempNote]);
  };

  //double click to detect edit
  const childEdit = (values) => {
    setEditValues(values);
    setEdit(true);
  };

  //change note folder status
  const changeFolderStatus = (labIndex, labKey) => {
    //eventually this will have to save and write to the db
    let statusChange = folderStatuses[labIndex];
    let newStatuses = [...folderStatuses];
    newStatuses[labIndex] = !statusChange;
    setFolders(newStatuses);
    props.studentRequest("/api/answers/updatefolderstatus", {
      id: Student_Id,
      labKey: labKey,
      newStatus: !statusChange,
      navId: Lesson_Id,
    });
  };

  //generate all the clickable note information
  const generateNoteButtons = () => {
    let noteButtons = [];
    Object.keys(noteData).forEach(function (labKey, i) {
      let labData = noteData[labKey];
      //if non zero, there are notes
      if (Object.keys(labData["notes"]).length > 0) {
        //create folder
        if (noteHeaders) {
          let labFolder = [
            <ListItemButton onClick={() => changeFolderStatus(i, labKey)}>
              <ListItemText primary={labKey} />
              {folderStatuses[i] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>,
          ];
          noteButtons.push(labFolder);
        }
        let noteKeys = Object.keys(labData.notes);
        let labNotes = [];
        noteKeys.forEach((noteKey) => {
          let noteValues = labData.notes[noteKey];
          let deleteInfo = [noteValues, noteKey, labKey];
          let noteButton = [
            <ListItem
              key={"noteid" + noteKey}
              disablePadding
              sx={{
                backgroundColor: noteValues.background,
                paddingRight: "12%",
              }}
            >
              <Tooltip title={noteValues.body} placement="right">
                <ListItemButton
                  onClick={() => {
                    setOpen(false);
                    props.close();
                    createNote(noteValues, noteKey);
                  }}
                >
                  <ListItemText
                    primary={noteValues.title}
                    secondary={noteValues.date}
                  />
                </ListItemButton>
              </Tooltip>
              <ListItemButton
                sx={{ position: "absolute", right: "-12%" }}
                onClick={() => {
                  setSelectedDelete(deleteInfo);
                  setDelete(true);
                }}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>,
          ];
          labNotes.push(noteButton);
        });
        if (noteHeaders) {
          let collapseComponent = [
            <Collapse in={folderStatuses[i]} timeout="auto" unmountOnExit>
              {labNotes}
            </Collapse>,
          ];
          noteButtons.push(collapseComponent);
        } else {
          noteButtons.push(labNotes);
        }
      }
    });
    return noteButtons;
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onKeyDown={toggleViz("open", false)}
    >
      <List>
        <ListItem key="add_new_note" disablePadding>
          <ListItemButton
            onClick={() => {
              props.close();
              setEditValues({
                title: "",
                body: "",
                background: "#EF9971",
                keyVal: count,
              });
              setEdit(true);
            }}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="New Note" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>{generateNoteButtons()}</List>
    </Box>
  );

  const noteEdit = () => {
    if (edit === true) {
      return (
        <div style={{ position: "relative", zIndex: 1001 }}>
          <Backdrop open={edit}>
            <Paper>
              <EditNote
                cancel={() => setEdit(false)}
                values={noteValues}
                save={saveNote}
              />
            </Paper>
          </Backdrop>
        </div>
      );
    }
  };

  const deleteClose = () => {
    setDelete(false);
  };

  const deleteNote = (noteValues) => {
    //check if it's displayed, if so, remove it from visibile notes
    var tempVisNoteData = visNotes.filter(
      (item) => item.keyVal != noteValues[1]
    );
    setNotes(tempVisNoteData);
    //remove from side nav
    var tempNoteData = noteData;
    delete tempNoteData[noteValues[2]].notes[noteValues[1]];
    setData(tempNoteData);
    setDelete(false);
    props
      .studentRequest("/api/answers/deletenote", {
        id: Student_Id,
        labKey: noteValues[2],
        updatedNotes: tempNoteData[noteValues[2]].notes,
        navId: Lesson_Id,
      })
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <React.Fragment key="note_manager">
        <Drawer anchor={"left"} open={open} onClose={toggleViz("open", false)}>
          {list()}
        </Drawer>
      </React.Fragment>
      {noteEdit()}
      <div>
        {visNotes.map(function (note, index) {
          return (
            <Note
              title={note.title}
              body={note.body}
              color={note.color}
              keyVal={note.keyVal}
              id={note._id}
              edit={childEdit}
              close={closeNote}
            />
          );
        })}
      </div>
      <div>
        <Dialog
          open={showDelete}
          onClose={deleteClose}
          aria-labelledby="alert-delete-title"
          aria-describedby="alert-delete-desc"
        >
          <DialogTitle id="alert-delete-title">
            {"Are you sure you want to delete this note?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-delete-desc">
              <b>Title</b>
              <br />
              {selectedNoteToDelete[0].title}
              <br />
              <b>Body</b>
              <br />
              {selectedNoteToDelete[0].body}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{ position: "absolute", left: "2%" }}
              onClick={deleteClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => deleteNote(selectedNoteToDelete)}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
