import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';

export default function TeacherDashboard() {

    let linkText = "/teacher/analytics";

    return (
        <div style={{marginBottom: 20}}>
            <Button variant="contained" color="primary" component={Link} to={linkText}>
                Analytics
            </Button>
            <br />
            <br />
            <br />
            <Button variant="contained" color="primary" component={Link} to={"/users"}>
                User Management
            </Button>
        </div>
    )
}