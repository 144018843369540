import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

export function IndividualKnowledgeWall() {
    return (
            <Grid container justify="center" alignItems="center" direction="column">
                <Grid item>
                    <Button variant="contained" color="primary" sx={{marginTop: 5}} onClick={() => console.log("do stuff")}>Idea Wall</Button>
                </Grid>
            </Grid>
    )
}

export default IndividualKnowledgeWall;