import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import TableContainer from '@material-ui/core/TableContainer';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import {UserTypeMap, getAcademicYears} from '../../constants';
import { Autocomplete } from '@material-ui/lab';
import { useGridRegisterStrategyProcessor } from '@mui/x-data-grid/hooks/core/strategyProcessing';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import AddIcon from '@mui/icons-material/Add';
import { StyledSpeedDial } from '../../ildlTheme';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles  = (theme) => ({
    pageMargin: {
        marginLeft: 275,
        marginTop: theme.spacing(3),
    },
    autoComplete: {
        "& div.MuiAutocomplete-endAdornment": {
            top: 'auto' // fix for the end adornment positioning too low
        }
    },
    selectCell: {
        display: "flex",
        flexDirection: "column",
    },
    selectMargin: {
        marginRight: "auto",
    },
    tableContainer: {
        width: "98%",
    },
});

class UserList extends React.Component{

    constructor(props) {

        super(props);

        // ['2050-2051','2049-2050','2048-2049',...] where the first element is the current academic year
        this.AcademicYears = getAcademicYears();

        this.state = {
            userList: [], // Users from the backend
            schools: [], // array of school names i.e. ["School One","School Two"]
            filterByUserTypeId: 1, // access_level of users we're showing in the table
            filterByStudentAcademicYear: this.AcademicYears[0], // Default to filtering for the most-recent academic year
            rowsPerPage: 20, // can reduce for testing, but should also add a smaller number to the rowsPerPageOptions options
            currentPage: 0, // zero indexed page number we're on
            filterByTeacherName: null, // Name of the selected teacher name that we're filtering on
            filterBySchoolName: null, // Name of the selected school that we're filtering on
            open: false
        };

    }

    componentDidMount(){
        // If the user is a researcher:
        //  we need to get a list of schools for filtering the user list
        if(this.props.auth.user.access === 3)
        {
            this.fetchSchools();
            
            // default to filtering by teacher
            this.setState({filterByUserTypeId: 2}); 

        }
        else { // If the user is a teacher

            // default to filtering by student
            this.setState({filterByUserTypeId: 1}); 

        }

        // fetch users, filtering for student who have a class in the current academic year
        this.fetchUsers(this.AcademicYears[0]);

        
    }

    fetchSchools = () => {
        // Get all the schools from the backend
        axios.get("/api/schools/getSchoolNames").then(response => {
            this.setState({schools: response.data});
        })
        .catch(e =>{
            console.log(e);
        });
    }

    fetchUsers = (studentAcademicYear) => {

        this.setState({isFetching: true});
        
        // Get a list of users we're allowed to manage from the backend
        axios.get("/api/users/getUsersForUserManagement", { params: { 'studentAcademicYear': studentAcademicYear}}).then(response => {
            this.setState({userList: response.data, isFetching: false});
        })
        .catch(e =>{
            console.log(e);
            this.setState({isFetching: false});
        });

    }

    handleUserTypeChange = (event) => {
        let value = event.target.value;
        this.setState({filterByUserTypeId: parseInt(value), currentPage: 0 });
    }

    handleStudentAcademicYearChange = (event) => {
        let value = event.target.value;
        this.setState({filterByStudentAcademicYear: value, currentPage: 0});
        this.fetchUsers(value);
    }

    handleRowsPerPageChange = (event) => {
        this.setState({rowsPerPage: parseInt(event.target.value)});
        this.setState({currentPage: 0});
    }

    handlePageChange = (event, newPage) => {
        this.setState({currentPage: newPage});
    }

    // Handle a Consent Given toggle change
    handleConsentChange = (event) => {

        let userId = event.target.id.split('_')[1];

        axios.post('/api/users/set-consent', {
            _id: userId,
            consent: event.target.checked
        }).then((response) => {

            if(!response.data.success)
            {
                if(response.data.errors !== undefined && response.data.errors.consent !== undefined)
                {
                    alert('Error saving Consent Given change - ' + response.data.errors.consent);
                }
                else
                {
                    alert('Error saving Consent Given change');
                }
            }
            else
            {
                // Update our userList state, setting the consent field on the appropriate user
                let userList = this.state.userList;
                let foundIndex = userList.findIndex(element => element._id === response.data.user_id)
                userList[foundIndex].consent = response.data.consent;
                this.setState({ userList: userList });
            }

        })
        .catch((error) => {
            console.log(error);
            alert('Unknown error saving Consent Given change');
        })

    }

    // From the list of users we have in the frontend return a list with the filters applied
    getFilteredUsersList = () => {

        let filteredList = this.state.userList.filter(user => user.auth_level === this.state.filterByUserTypeId); // Filter by User Type
        
        // If a teacher name has been selected
        if(this.state.filterByTeacherName)
        {
            // Find users who have this teacher
            filteredList = filteredList.filter(user => user.teacherNames.indexOf(this.state.filterByTeacherName) > -1);
        }

        // If a school has been selected
        if(this.state.filterBySchoolName)
        {
            filteredList = filteredList.filter(user => user.school === this.state.filterBySchoolName);
        }

        return filteredList;

    }

    // From the list of users we have in the frontend,
    // return a list with the filters and pagination applied
    getFilteredAndSlicedUsersToShowInTable = () => {

        let filteredList = this.getFilteredUsersList();
        filteredList = filteredList.slice(this.state.currentPage * this.state.rowsPerPage, // Slice out the rows for the current page in the table
                                    this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage); 
                          
        return filteredList;
    }

    onSchoolFilterChange = (event, value) => {

        if(value === null)
        {
            this.setState({filterBySchoolName: null, currentPage: 0 });
        }
        else
        {
            // value is a string
            this.setState({filterBySchoolName: value, currentPage: 0 });
        }
    }

    onTeacherFilterChange = (event, value) => {
        
        if(value === null)
        {
            this.setState({filterByTeacherName: null, currentPage: 0 });
        }
        else
        {
            // value is an object like { label: 'Teacher Name', 'id': 'Teacher Name }
            this.setState({filterByTeacherName: value.id, currentPage: 0 });
        }
                
    }

    // From our list of users, get the first class where each user is a student, and then get the first teacher for each of those class
    // returns [{label: xxx, id: yyy},...]
    getTeacherFilterOptions = () => {

        let teacherNames = [];
        
        // For each user
        this.state.userList.forEach((user) => {

            // For every teacher this user has
            user.teacherNames.forEach((teacherName) => {

                // If we don't already have this name in our list
                if(teacherNames.indexOf(teacherName) < 0)
                {
                    // Add it now
                    teacherNames.push(teacherName);
                }

            });

        });

        return teacherNames.map((teacherName) => { return { label: teacherName, id: teacherName }; });
        
    }
    setOpen = () => {
        this.setState({ open: !this.state.open});
    }

    // Reference docs for implementing pagination, sorting, selection, etc. https://mui.com/material-ui/react-table/
    render() {
        const { classes } = this.props;
        const userTypes = Object.keys(UserTypeMap);
        const academicYears = this.AcademicYears;

        return (
            <div className={classes.pageMargin}>
                <Grid justifyContent="space-between" container spacing={2}>
                    <Grid item xs={3}>
                    { this.props.auth.user.access === 3
                    ?
                        <Typography variant="subtitle1" component="h2" color="primary">Manage Users</Typography>
                    :
                        <Typography variant="subtitle1" component="h2" color="primary">Manage Students</Typography>
                    }
                    </Grid>
                    <Grid item xs={3} style={{position: "relative"}}>
                        <StyledSpeedDial
                            ariaLabel="Manage users actions"
                            open={this.state.open}
                            onClose={this.setOpen}
                            onOpen={this.setOpen}
                            icon={<SpeedDialIcon />}
                            direction="down"
                            style={{position: "absolute", top: "25px", right: "25px"}}
                        >
                            <SpeedDialAction
                                key="individual"
                                icon={<Link to={"/user/"} component={RouterLink}><AddIcon /></Link>}
                                tooltipTitle="Add&nbsp;individual"
                                tooltipOpen
                                onClick={this.setOpen}
                            />
                            <SpeedDialAction
                                key="upload"
                                icon={<Link to={"/uploadusers"} component={RouterLink}><AddIcon /></Link>}
                                tooltipTitle="Batch&nbsp;upload"
                                tooltipOpen
                                onClick={this.setOpen}
                            />
                        </StyledSpeedDial>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    
                    { /* If the logged-in user is a Researcher we'll allow them to filter by user type */ }
                        { this.props.auth.user.access === 3
                        ?
                            <Grid item xs={6} sm={6} md={3} lg={2} className={classes.selectCell}>
                                <FormControl variant="outlined" className={classes.selectMargin} style={{minWidth: "140px", maxWidth: "100%"}}>
                                    <InputLabel id="user-type">User Type</InputLabel>
                                    <Select
                                        labelId="user-type-select-label"
                                        id="user-type-select"
                                        label="User Type"
                                        onChange={this.handleUserTypeChange}
                                        value={this.state.filterByUserTypeId}
                                        style={{maxWidth: "100%"}}
                                    >{
                                        userTypes.map((userTypeKey, i) => (
                                            <MenuItem key={"userTypeRow" + i} value={userTypeKey}>{UserTypeMap[userTypeKey]}</MenuItem>
                                        ))
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                        : 
                            null
                        }
                    
                    { this.state.filterByUserTypeId === 1 
                    ?
                    <Grid item xs={6} sm={6} md={3} lg={2} className={classes.selectCell}>
                        <FormControl variant="outlined" className={classes.selectMargin} style={{minWidth: "180px"}}>
                            <InputLabel id="user-type">Student Academic Year</InputLabel>
                            <Select
                                labelId="student-academic-year-select-label"
                                id="student-academic-year-select"
                                label="Student Academic Year"
                                onChange={this.handleStudentAcademicYearChange}
                                value={this.state.filterByStudentAcademicYear}
                            >{
                                academicYears.map((academicYear, i) => (
                                    <MenuItem key={academicYear} value={academicYear}>{academicYear}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                    :
                    null
                    }
                </Grid>
                <br/>
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                { /* If the logged-in user is a Researcher we'll allow them to filter by School */ }
                                { this.props.auth.user.access === 3
                                ?
                                    <Autocomplete
                                        className={classes.autoComplete}
                                        size="small"
                                        autoSelect={true}
                                        onChange={this.onSchoolFilterChange}
                                        //getOptionSelected={(option, value) => option.id === value.id}
                                        options={this.state.schools}
                                        //getOptionLabel={(option) => option.label}
                                        id="filterBySchool"
                                        renderInput={(params) => <TextField {...params} label="School" />}
                                    />
                                :
                                <React.Fragment>School</React.Fragment>
                                }
                                </TableCell>
                                <TableCell>
                                { /* If the logged-in user is a Researcher we'll allow them to filter by Teacher */ }
                                { this.props.auth.user.access === 3
                                ?
                                    <Autocomplete
                                        className={classes.autoComplete}
                                        size="small"
                                        autoSelect={true}
                                        onChange={this.onTeacherFilterChange}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        options={this.getTeacherFilterOptions()}
                                        getOptionLabel={(option) => option.label}
                                        id="filterByTeacher"
                                        renderInput={(params) => <TextField {...params} label="Teacher" />}
                                    />
                                :
                                <React.Fragment>Teacher</React.Fragment>
                                }
                                </TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email Address</TableCell>
                                <TableCell>Consent Given?</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.getFilteredAndSlicedUsersToShowInTable().map((user, i) => (
                                <TableRow key={"userRow" + i}> 
                                    <TableCell>{user.school}</TableCell>
                                    <TableCell>{user.teacherNames.join(" | ")}</TableCell>                                   
                                    <TableCell>{user.last_name}, {user.first_name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        { /* If the logged-in user is a Researcher we'll allow them to toggle the Consent field */ }
                                        { this.props.auth.user.access === 3
                                        ?
                                            <FormControl>
                                                <FormGroup>
                                                    {/* By default Switch uses the secondary color of the mui theme. Manually overriding it to primary here */}
                                                    <FormControlLabel control={<Switch id={"consent_" + user._id}
                                                        color="primary"
                                                        onChange={ e => { this.handleConsentChange(e); } }
                                                        checked={user.consent ? true : false}
                                                        />} label="Consent Given?" />
                                                </FormGroup>
                                            </FormControl>
                                        :
                                        <React.Fragment>{user.consent ? "Yes" : "No"}</React.Fragment>
                                        }
                                    </TableCell>
                                    <TableCell><Link to={"/user/" + user._id} component={RouterLink}>Edit</Link></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination 
                                    count={this.getFilteredUsersList().length}
                                    page={this.state.currentPage}
                                    onPageChange={this.handlePageChange}
                                    rowsPerPageOptions={[20]}
                                    rowsPerPage={this.state.rowsPerPage}
                                    onRowsPerPageChange={this.handleRowsPerPageChange}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        )
    }

}

useGridRegisterStrategyProcessor.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
   ) (withStyles(styles) (UserList));

