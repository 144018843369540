import { useState, useEffect } from "react";
import Iframe from "react-iframe";
import PageSimInput from "./PageSimInput";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PageRedirectButton from "./PageRedirectButton";
import axios from "axios";

export function PageRollercoasterSim(props) {
  const { simUrl, simText, navigation, activeLab } =
    props;

  // State for holding current values of input fields
  const [inputValues, setInputValues] = useState({});
  const [answers, setAnswers] = useState(props.answers);
  const [tableKey, setTableKey] = useState("");
  const [tableHeaders, setHeaders] = useState(props.tableHeaders)

  const inputs = tableHeaders
    ? tableHeaders.options.map((element) => {
        var label = element.label.split("(");
        var questionText;
        var adornmentText;
        if (label[1] !== undefined) {
          questionText = label[0].slice(0, -1);
          adornmentText = label[1].slice(0, -1);
        } else {
          questionText = label[0];
          adornmentText = "";
        }
        return {
          quest: questionText,
          adorn: adornmentText,
          column: element.name,
        };
      })
    : [];

  const inputTypes = tableHeaders?.inputType || [];

  useEffect(async () => {
    //on refresh, tableHeaders can be null since the component loads before the api is called in Notebook.js
    if (
      navigation &&
      navigation.Labs[activeLab].pages.data &&
      navigation.Labs[activeLab].tabTitles.data === "Data" &&
      tableHeaders === null
    ) {
      axios
        .post("/api/navigation/getdatatableheaders", {
          page: navigation.Labs[activeLab].pages.data,
        })
        .then((response) => {
          let data = response.data;
          setHeaders(data);
          setTableKey(data.fieldKey);
        });
    } 
    if (tableHeaders != null && tableKey == "") {
      setTableKey(tableHeaders.fieldKey);
    }
    if (answers == null && activeLab > -1 && navigation.Labs[activeLab].type != "essay" && navigation.Labs[activeLab].tabTitles.data !== "") {
      let pcres = await axios.post("/api/getPage/page", {
        pageId: navigation.Labs[props.activeLab].pages.data,
        studentId: navigation.Student_Id,
        lessonId: navigation.Lesson_Id,
        classroom: navigation.Classroom,
      });
      for (const key in pcres.data[1]) {
        if (key.includes("resulttable")) {
          setAnswers(pcres.data[1][key]);
        }
      }
    }
  }, []);

  // Callback function passed to each PageSimInput component to handle updating the input field states
  const handleChange = (event) => {
    const newInputValues = { ...inputValues };
    if (event.target.value === "") {
      delete newInputValues[event.target.name];
    } else {
      newInputValues[event.target.name] = event.target.value;
    }
    setInputValues(newInputValues);
  };

  // Function for submitting (and clearing) all inputs if complete as a new row in the active data table
  const submitInputs = () => {
    // Only submit if no inputs are empty
    if (inputs.length === Object.keys(inputValues).length) {
      const newData = answers
        ? [...answers.answer, inputValues]
        : [inputValues];
      props.returnData({ answer: newData }, tableKey);
      setAnswers({ answer: newData });

      // Clear all input fields
      setInputValues({});
    }
  };

  // Function to ensure iframe url matches http protocol of host environment 
  const setProtocol = (url) => {
    return window.location.protocol === 'https:' ? url.replace('http:', 'https:') : url;
  }

  // If user hasn't gotten through the introduction to start any of the labs yet, don't render anything
  if (!activeLab) {
    return (
      <div>
        <p>Complete the introduction first!</p>
      </div>
    );
  }

  if (!simUrl && !simText) {
    return (
      <div>
        <p>No information provided for the simulation of this lab.</p>
      </div>
    );
  } else if (!simUrl) {
    return (
      <div className="rc_sim">
        {tableHeaders ? (
          <Box textAlign="center">
            <Typography style={{ marginTop: -35 }}>
              <h2>Experiment for {navigation.Labs[activeLab].labName}</h2>
            </Typography>
          </Box>
        ) : null}
        <Grid container>
          <Grid item xs={7} style={{ marginRight: 10 }}>
            {simText}
          </Grid>
          {/* Only render inputs if active lab has a table */}
          {tableHeaders ? (
            <Grid item xs={3.5}>
              {inputs
                ? inputs.map((input, index) => (
                    <PageSimInput
                      key={input.column}
                      question={input.quest}
                      adornment={input.adorn}
                      name={input.column}
                      value={inputValues[input.column] || ""}
                      handleChange={handleChange}
                      inputType={inputTypes[index]}
                    />
                  ))
                : null}
              <Button
                variant="contained"
                color="primary"
                onClick={() => submitInputs()}
              >
                Submit
              </Button>
              <br/>
              <br/>
              <PageRedirectButton buttonText="Go to Data" url={"/notebook/" + activeLab + "/data"} />
            </Grid>
          ) : null}
        </Grid>
      </div>
    );
  } else {
    return (
      <div className="rc_sim">
            {tableHeaders ? (
            <Box textAlign="center">
              <Typography style={{ marginTop: -35 }}>
                <h2>Experiment for {navigation.Labs[activeLab].labName}</h2>
              </Typography>
            </Box>
          ) : null}
        <Grid container columnSpacing={1.5}>
          <Grid item xs={9.5}>
          { /* 16:9 aspect ratio so 720/405, 1024/576, 1280/720, etc. */}
          { /* For a 1024px-width sim, the full-width of the page with sidebar navigation is ~1240px */ }
            <Iframe
              url={setProtocol(simUrl)}
              width="900px"
              height="506px"
              frameBorder="0"
              id="rollercoaster"
              display="initial"
              position="relative"
            />
          </Grid>
        {/* Only render inputs if active lab has a table */}
        {tableHeaders ? (
            <Grid item xs={2.5}>
              {inputs
                ? inputs.map((input, index) => (
                    <PageSimInput
                      isFirst={(index === 0 )}
                      key={input.column}
                      question={input.quest}
                      adornment={input.adorn}
                      name={input.column}
                      value={inputValues[input.column] || ""}
                      handleChange={handleChange}
                      inputType={inputTypes[index]}
                    />
                  ))
                : null}
              <Button
                style={{ marginTop: 25 }}
                variant="contained"
                color="primary"
                onClick={() => submitInputs()}
              >
                Submit
              </Button>
              <br/>
              <br/>
              <PageRedirectButton buttonText="Go to Data" url={"/notebook/" + activeLab + "/data"} />
            </Grid>
          ) : null}
          </Grid>
      </div>
    );
  }
}

export default PageRollercoasterSim;