import { Component } from "react";
import { connect } from "react-redux";

import { logoutUser } from "../../actions/authActions";
import {
  getNavigation,
  getTeacherNavigation,
  setActiveLab,
  setActiveTable,
} from "../../actions/lessonActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";

import StudentDashboard from "./dashboard/StudentDashboard";
import ResearchDashboard from "./dashboard/ResearchDashboard";
import TeacherDashboard from "./teacher/TeacherDashboard";
import { withStyles } from '@material-ui/core/styles';
import store from "../../store";
import { setTheme } from "../../actions/authActions";
import { adminTheme, adminMuiTheme } from "../../ildlTheme";

const styles = (theme) => ({
  pageMargin: {
    marginLeft: "max(275px, 20%)",
    marginRight: "20%",
    marginTop: theme.spacing(3),
  }
});

class Dashboard extends Component {
  
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  goToNotebook() {
    this.history.push("/notebook/instruct");
  }

  componentDidMount() {
    const { user } = this.props.auth;
    if (user.access === 0) {
      this.props.history.push("/access");
    } else if (user.access === 1 || user.access === -1) {
      let data = { studentId: user.id };
      this.props.getNavigation(data);
      // Automatically redirect students to the notebook
      this.props.history.push("/notebook");
    } else if (user.access === 2) {
      let data = { studentId: user.id };
      this.props.getTeacherNavigation(data);
      if (this.props.auth.theme.material !== adminTheme) {
        // Return to admin theme after teacher viewed the student notebook
        store.dispatch(setTheme({material: adminTheme, mui: adminMuiTheme}));
      }
    }
  }

  dashboardRenderer(userLevel) {
    if (userLevel === 1) {
      // Currently not used since we auto-direct students to notebook
      return <StudentDashboard />;
    } else if (userLevel === 3) {
      return <ResearchDashboard />;
    } else if (userLevel === 2) {
      return (
        <div>
          <StudentDashboard />
          <br />
          <br />
          <TeacherDashboard />
        </div>
      );
    }
  }

  render() {
    const { user } = this.props.auth;
    const { classes } = this.props;

    return (
      <div className={classes.pageMargin}>
        <br />
        <Typography style={{ fontSize: 34 }} align="center">
          Hey there {user.first_name} {user.last_name}
        </Typography>
        <br />
        <br />
        <Typography align="center">You are logged in.</Typography>
        <br />
        <Box textAlign="center">
          <br />
          {this.dashboardRenderer(user.access)}

          <Button
            variant="contained"
            color="secondary"
            style={{
              width: "150px",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "1rem",
            }}
            onClick={this.onLogoutClick}
          >
            Logout
          </Button>
        </Box>
      </div>
    );
  }
}

/*
Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    getLesson: PropTypes.func.isRequired,
    lesson: PropTypes.object.isRequired,
    getNavigation: PropTypes.func.isRequired,
    navigation: PropTypes.object.isRequired
}
*/

const mapStateToProps = (state) => ({
  auth: state.auth,
  navigation: state.navigation,
});

export default connect(mapStateToProps, {
  logoutUser,
  getNavigation,
  setActiveLab,
  setActiveTable,
  getTeacherNavigation,
})(withStyles(styles) (Dashboard));
