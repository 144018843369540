import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PieChartIcon from "@mui/icons-material/PieChart";
import PeopleIcon from "@mui/icons-material/People";
import AppsIcon from "@material-ui/icons/Apps";
import SchoolIcon from "@mui/icons-material/School";
import DeleteIcon from '@mui/icons-material/Delete';
import { SvgIcon, CssBaseline } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/styles";

import { ReactComponent as LogoIcon } from "../../assets/ILDL-logo.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const styles = (theme) => ({
  drawerClass: {
    width: 240,
    flexShrink: 0,
    '& .MuiDrawer-paper': { 
      width: 240, 
      boxSizing: 'border-box', 
      backgroundColor: '#f9f9f9' }
  }
});

const NavListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  "& .MuiListItemButton-root": {
    color: theme.palette.text.secondary,
    borderLeft: "2px solid transparent",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  "& .MuiListItemButton-root:hover": {
    borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.dark,
    },
  },
  "& .MuiListItemIcon-root": {
    color: theme.palette.info.main,
  },
}));

function Navbar(props) {
  const location = useLocation();
  const { classes } = props;
  // do not show nav bar to students or unauthenticated users
  const showNav =
    props.auth.user.access !== 1 &&
    props.auth.isAuthenticated &&
    !location.pathname.includes("notebook");
  if (showNav) {
    return (
      <Fragment>
        <CssBaseline />
          <AppBar
            position="fixed"
            style={{zIndex: props.auth.theme.material.zIndex.drawer + 1 }}
          >
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                style={{ display: 'inline-flex', alignItems: 'center'}}
              >
                <SvgIcon component={LogoIcon} fontSize="large" style={{ paddingRight: props.auth.theme.material.spacing(2)}} />
                ILDL Admin Center
              </Typography>
            </Toolbar>
          </AppBar>
          <Toolbar />
          <Drawer
            variant="permanent"
            className={classes.drawerClass}
          >
            <Toolbar />
            <Box style={{ overflow: 'auto', width: '100%' }}>
              <Typography variant="h6" noWrap component="div">
                {props.auth.user.name}
              </Typography>
              <nav>
                <List>
                  <NavListItem disableGutters={true}>
                    <ListItemButton key="Home" component={Link} to="/dashboard">
                      <ListItemIcon>
                        <PieChartIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                  </NavListItem>
                  <NavListItem disableGutters={true}>
                    <ListItemButton key="Users" component={Link} to="/users">
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItemButton>
                  </NavListItem>
                  {props.auth.user.access === 3 ? (
                    <React.Fragment>
                      <NavListItem disableGutters={true}>
                        <ListItemButton
                          key="Schools"
                          component={Link}
                          to="/schools"
                        >
                          <ListItemIcon>
                            <SchoolIcon />
                          </ListItemIcon>
                          <ListItemText primary="Schools" />
                        </ListItemButton>
                      </NavListItem>
                    </React.Fragment>
                  ) : null}
                  <NavListItem disableGutters={true}>
                    <ListItemButton
                      key="Data"
                      component={Link}
                      to="/datadownload"
                    >
                      <ListItemIcon>
                        <AppsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Data" />
                    </ListItemButton>
                  </NavListItem>
                  {props.auth.user.access === 3 ? (
                    <React.Fragment>
                      <NavListItem disableGutters={true}>
                        <ListItemButton
                          key="DeleteData"
                          component={Link}
                          to="/deletedata"
                        >
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText primary="Delete Data" />
                        </ListItemButton>
                      </NavListItem>
                    </React.Fragment>
                  ) : null}
                </List>
              </nav>
            </Box>
          </Drawer>
      </Fragment>
    );
  } else {
    return null;
  }
}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withStyles(styles) (Navbar));
